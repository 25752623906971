import axios from "axios";
import { CHOWDECK_RELAY_BASE } from "../configs/app";
import { setChowdeckRelayHeader } from "../utils/helpers";

export const getDeliveryFee = async ({queryKey}) => {
     let header = await setChowdeckRelayHeader();
     let data = {
        "source_address":{
            "latitude":queryKey[1]?.lat,
            "longitude":queryKey[1]?.lng
        },
        "destination_address":{
            "latitude":queryKey[2]?.lat,
            "longitude":queryKey[2]?.lng
        },
        // "estimated_order_amount":100000
    }
    let res = await axios.post(`${CHOWDECK_RELAY_BASE}/delivery/fee`, data, header)
    return res.data
}

export const getWalletBalance = async () => {
    let header = await setChowdeckRelayHeader();
   let res = await axios.get(`${CHOWDECK_RELAY_BASE}/wallet/balance`, header)
   return res.data
}
export const getVirtualAccountDetails = async () => {
    let header = await setChowdeckRelayHeader();
   let res = await axios.get(`${CHOWDECK_RELAY_BASE}/wallet/virtual-account`, header)
   return res.data
}

export const getDeliveryData= async ({queryKey}) => {
    let header = await setChowdeckRelayHeader();
  let url = `${CHOWDECK_RELAY_BASE}/delivery/${queryKey[1]}` 
  const res = await axios.get(url,header);
  return res.data;
};