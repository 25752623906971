import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Badge, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaginatedProducts, fetchProducts } from "../../api/admin.api";
import { getOutletsByState, getStates } from "../../api/app.api";
import ViewProductModal from "../../components/Modals/ViewProductModal";
import AdminLayout from "../../layouts/AdminLayout";
import { setProducts } from "../../store/slices/adminSlice";
import { displayAmount, makeTitleCase, showAlert } from "../../utils/helpers";
import moment from "moment";
import DeleteProductModal from "../../components/Modals/DeleteProductModal";
import CreateProductModal from "../../components/Modals/CreateProductModal";
import UpdateProductModal from "../../components/Modals/UpdateProductModal";

const Products = () => {
  let dispatch = useDispatch();
  const [selectedState, setSelectedState] = useState(null);
  const [outletsInState, setOutletsInState] = useState(null);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const { products } = useSelector((state) => state.admin);
  const [allProducts, setAllProducts] = useState([]);
  const [productStatus, setProductStatus] = useState("all")

  const { data, isLoading, isSuccess } = useQuery(["states"], getStates, {
    onSuccess: (data) => {
      if (!data.status) {
        showAlert({
          title: "Error!",
          msg: "An internal error has occured",
          icon: "error",
          btnText: "close",
        });
      }
    },
    onError: (err) =>
      showAlert({
        title: "Error!",
        msg: "An internal error has occured",
        icon: "error",
        btnText: "close",
      }),
  });

  const {
    data: paginatedProducts,
    isLoading: loadingProducts,
    isSuccess: productsSuccess,
    error: productsError,
    status,
    refetch,
  } = useQuery(
    ["allProducts", selectedOutlet, page, limit, productStatus],
    fetchPaginatedProducts,
    {
      // enabled:false,
      onSuccess: (data) => {
        if (!data?.status) {
          return showAlert({
            title: "Oops!",
            msg:
              typeof data.errors[0].msg == "string"
                ? data.errors[0].msg
                : data.errors[0].msg.message,
            icon: "error",
            btnText: "Close",
          });
        }
        limit === 1000
          ? setAllProducts(data?.value?.products)
          : dispatch(setProducts(data?.value?.products));
      },
      onError: (err) =>
        showAlert({
          title: "Error!",
          msg: "An internal error has occurred",
          icon: "error",
          btnText: "Close",
        }),
    }
  );

  const {} = useQuery({
    queryKey: ["getOutletsInState", selectedState],
    queryFn: getOutletsByState,
    onSuccess: (data) => {
      if (!data?.status && selectedState) {
        return showAlert({
          title: "Oops!",
          msg:
            typeof data.errors[0].msg == "string"
              ? data.errors[0].msg
              : data.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      }
      setOutletsInState(data.value);
    },
    onError: (err) => {
      showAlert({
        title: "Error!",
        msg: "An internal error has occurred",
        icon: "error",
        btnText: "Close",
      });
    },
  });

  const handlePagination = (type) => {
    // e.preventDefault()
    if (type === "prev") {
      setPage((prev) => Math.ceil(Math.max(prev - 1, 1)));
    } else if (type === "next") {
      setPage((prev) => prev + 1);
    } else {
      setPage(type);
    }
  };

  const handleClose = (type) => {
    switch (type) {
      case "create":
        refetch();
        setShowCreateModal(false);
        break;
      case "view":
        setShowViewModal(false);
        setSelectedProduct(null);
        break;
      case "edit":
        refetch();
        setShowEditModal(false);
        setSelectedProduct(null);
        break;
      case "delete":
        refetch();
        setShowDeleteModal(false);
        setSelectedProduct(null);
        break;
      default:
        return;
    }
  };

  const handleEdit = (item) => {
    setSelectedProduct(item);
    setShowEditModal(true);
  };

  const handleDelete = (item) => {
    setSelectedProduct(item);
    setShowDeleteModal(true);
  };

  const handleView = (item) => {
    setSelectedProduct(item);
    setShowViewModal(true);
  };

  const handleSearch = (val) => {
    if (!selectedState) {
      return showAlert({
        title: "Oops!",
        msg: "You have to select a Region first",
        icon: "error",
        btnText: "Close",
      });
    }
    if (!selectedOutlet) {
      return showAlert({
        title: "Oops!",
        msg: "You have to select an Outlet",
        icon: "error",
        btnText: "Close",
      });
    }
    val = val.toLowerCase().trim();
    if (limit != 1000) {
      return setLimit(1000);
    }
    let searchedProducts = allProducts.filter(
      (item) =>
        item.name.toLowerCase().includes(val) ||
        item?.category.includes(val) ||
        item?.product_price == val
    );
    dispatch(setProducts(searchedProducts));
  };

  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Products</h1>
          <Button
            onClick={() => setShowCreateModal(true)}
            variant="primary"
            size="md"
          >
            Create New Product
          </Button>
        </div>

        <div className="card shadow mb-4">
          <div className="card-body">
            <Form>
              <Row>
                <Col>
                  {isLoading && <Spinner animation="border" size="lg" />}
                  {!isLoading && data && (
                    <Form.Group className="mb-3" controlId="formSelect">
                      <Form.Label>Select Region</Form.Label>
                      <Form.Select
                        onChange={(e) => setSelectedState(e.target.value)}
                        value={selectedState}
                      >
                        <option selected disabled value={null}>
                          {" "}
                          Choose{" "}
                        </option>
                        {data?.value?.map((val, index) => (
                          <option
                            key={`dbw-${Math.floor(
                              Math.random() * 9999
                            )}-${index}`}
                            value={val._id}
                          >
                            {makeTitleCase(val.name)}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  )}
                </Col>

                {isLoading && <Spinner animation="border" size="lg" />}
                {!isLoading && outletsInState && (
                  <Col>
                    <Form.Group className="mb-3" controlId="formOutletSelect">
                      <Form.Label>Select Outlet</Form.Label>
                      <Form.Select
                        onChange={(e) => setSelectedOutlet(e.target.value)}
                        value={selectedOutlet}
                      >
                        <option selected value={null}>
                          {" "}
                          Choose{" "}
                        </option>
                        {outletsInState?.map((val, index) => (
                          <option
                            key={`outletinstate-${Math.floor(
                              Math.random() * 9999
                            )}-${index}`}
                            value={val._id}
                          >
                            {makeTitleCase(val.name)}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                )}

                <Col>
                  <Form.Label>Search for an item</Form.Label>
                  <Form.Control
                    typeof="search"
                    onChange={(e) => handleSearch(e.target.value)}
                    className="form-control"
                    style={{ minHeight: 55 }}
                    placeholder="Search by name, category or amount"
                  />
                </Col>

               {selectedOutlet && <Col>
                  <Form.Label>Filter Availability</Form.Label>
                  <Form.Select
                        onChange={(e) => setProductStatus(e.target.value)}
                        value={productStatus}
                      >
                        <option selected value={"all"}>All Products</option>
                        <option value="available">Available Products</option>
                        <option value="unavailable">Unavailable Products</option>
                      </Form.Select>
                </Col>}
              </Row>
            </Form>
          </div>
        </div>

        {selectedState && (
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="table-responsive">
                {status === "success" && paginatedProducts && (
                  <div className="row my-3">
                    <div className="col-sm-12 col-md-5">
                      <div
                        className="dataTables_info"
                        id="dataTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing {limit * (page - 1) + 1} to{" "}
                        {page * limit < paginatedProducts?.value?.totalDocument
                          ? page * limit
                          : paginatedProducts?.value?.totalDocument}{" "}
                        of {paginatedProducts?.value?.totalDocument} items
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="dataTable_paginate"
                      >
                        <ul className="pagination" style={{ float: "right" }}>
                          <li
                            className={`paginate_button page-item previous ${
                              page === 1 ? "disabled" : ""
                            }`}
                            id="dataTable_previous"
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() => handlePagination("prev")}
                            >
                              Previous
                            </a>
                          </li>
                          {[...Array(paginatedProducts?.value?.totalPages)].map(
                            (val, index) => (
                              <li
                                key={`dbwf-${index}`}
                                className={`paginate_button page-item  ${
                                  page == index + 1 ? "active" : ""
                                }`}
                              >
                                <a
                                  onClick={() => handlePagination(index + 1)}
                                  className="page-link"
                                >
                                  {index + 1}
                                </a>
                              </li>
                            )
                          )}
                          <li
                            className={`paginate_button page-item next ${
                              page * limit >
                              paginatedProducts?.value?.totalDocument
                                ? "disabled"
                                : ""
                            }`}
                            id="dataTable_next"
                          >
                            <a
                              onClick={() => handlePagination("next")}
                              className="page-link"
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {loadingProducts && selectedState && (
                  <Spinner animation="border" size="lg" />
                )}
                {!loadingProducts && products && (
                  <table
                    className="table table-bordered text-center"
                    id="dataTable"
                    width="100%"
                    cellSpacing={0}
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Available</th>
                        <th>Category</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>S/N</th>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Available</th>
                        <th>Category</th>
                        <th>Action</th>
                      </tr>
                    </tfoot>
                    <tbody>
                      {products &&
                        products?.length > 0 &&
                        products?.map((item, index) => (
                          <tr
                            key={`${Math.floor(
                              Math.random() * 10000
                            )}-${index}`}
                          >
                            <td>{limit * (page - 1) + 1 + index}</td>
                            <td>
                              <div
                                onClick={() => handleView(item)}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <div
                                  style={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: 10,
                                    marginRight: 15,
                                    backgroundImage: `url(${item.image_url})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                  }}
                                ></div>
                                {makeTitleCase(item.name)}
                              </div>
                            </td>
                            <td>
                              {item.is_promo && item.sale_price !== null ? (
                                <>
                                  <span
                                    style={{
                                      textDecorationLine: "line-through",
                                    }}
                                  >
                                    {displayAmount(item.product_price)}
                                  </span>
                                  <span> {displayAmount(item.sale_price)}</span>
                                </>
                              ) : (
                                displayAmount(item.product_price)
                              )}
                            </td>
                            <td>
                              {item.available ? (
                                <Badge className="mr-1" pill bg="success">
                                  On Sale
                                </Badge>
                              ) : (
                                <Badge className="mr-1" pill bg="danger">
                                  Unavailable
                                </Badge>
                              )}
                            </td>

                            <td>
                              {/* {moment(item?.created).format(  "MMM Do YYYY, h:mm A" )} */}
                              {item.category.map((item, index) =>
                                item === "all-products" ? null : (
                                  <Badge
                                    key={`bdh-${Math.floor(
                                      Math.random() * 9999
                                    )}`}
                                    className="mr-1"
                                    pill
                                    bg="secondary"
                                  >
                                    {makeTitleCase(item)}
                                  </Badge>
                                )
                              )}
                            </td>

                            <td
                              style={{
                                display: "flex",
                                aligItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                size="sm"
                                onClick={() => handleView(item)}
                                variant="outline-primary"
                              >
                                <i className="fas fa-eye"></i>
                              </Button>
                              <Button
                                size="sm"
                                onClick={() => handleEdit(item)}
                                variant="outline-warning"
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </Button>
                              <Button
                                size="sm"
                                onClick={() => handleDelete(item)}
                                variant="outline-danger"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                {status === "success" && paginatedProducts && (
                  <div className="row my-3">
                    <div className="col-sm-12 col-md-5">
                      <div
                        className="dataTables_info"
                        id="dataTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing {limit * (page - 1) + 1} to{" "}
                        {page * limit < paginatedProducts?.value?.totalDocument
                          ? page * limit
                          : paginatedProducts?.value?.totalDocument}{" "}
                        of {paginatedProducts?.value?.totalDocument} items
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="dataTable_paginate"
                      >
                        <ul className="pagination" style={{ float: "right" }}>
                          <li
                            className={`paginate_button page-item previous ${
                              page === 1 ? "disabled" : ""
                            }`}
                            id="dataTable_previous"
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() => handlePagination("prev")}
                            >
                              Previous
                            </a>
                          </li>
                          {[...Array(paginatedProducts?.value?.totalPages)].map(
                            (val, index) => (
                              <li
                                key={`dbwf-${index}`}
                                className={`paginate_button page-item  ${
                                  page == index + 1 ? "active" : ""
                                }`}
                              >
                                <a
                                  onClick={() => handlePagination(index + 1)}
                                  className="page-link"
                                >
                                  {index + 1}
                                </a>
                              </li>
                            )
                          )}
                          <li
                            className={`paginate_button page-item next ${
                              page * limit >
                              paginatedProducts?.value?.totalDocument
                                ? "disabled"
                                : ""
                            }`}
                            id="dataTable_next"
                          >
                            <a
                              onClick={() => handlePagination("next")}
                              className="page-link"
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {showCreateModal && (
        <CreateProductModal
          states={data?.value}
          show={showCreateModal}
          close={() => handleClose("create")}
        />
      )}
      {selectedProduct !== null && (
        <ViewProductModal
          product={selectedProduct}
          show={showViewModal}
          close={() => handleClose("view")}
        />
      )}
      {selectedProduct !== null && (
        <DeleteProductModal
          product={selectedProduct}
          show={showDeleteModal}
          close={() => handleClose("delete")}
        />
      )}
      {selectedProduct !== null && (
        <UpdateProductModal
          states={data?.value}
          product={selectedProduct}
          show={showEditModal}
          close={() => handleClose("edit")}
        />
      )}
    </AdminLayout>
  );
};

export default Products;
